import React from "react"
import { graphql } from "gatsby"

import { PageStructure } from "../ui/atoms"
import { ProjectTemplate } from "../ui/templates"
import { IPhoto } from "../ui/organisms/PhotoSection/PhotoSection"

import { getNavLinks } from "../pages/index"

interface IProject {
  [key: string]: any
}

interface IWPConfig {
  [key: string]: any
}

interface IGraphQLNode<TNode> {
  node: TNode
}

export interface ProjectPageProps {
  data: {
    allWpProject: {
      edges: IGraphQLNode<IProject>[]
    }
    allWpConfig: {
      edges: IGraphQLNode<IWPConfig>[]
    }
  }
}

interface IRawPhoto {
  [key: string]: any
}

const getPhotos = (data: IRawPhoto[]): IPhoto[] =>
  data
    ? data.map((photo) => ({
        src: photo.localFile?.childImageSharp?.fluid?.src,
      }))
    : []

// markup
const ProjectPage: React.FC<ProjectPageProps> = (data: ProjectPageProps) => {
  const project = data.data.allWpProject.edges[0].node
  const config = data.data.allWpConfig.edges[0].node.configFields

  return (
    <PageStructure
      title={project.title}
      description={`${project.title} Construction Project Details`}
    >
      <ProjectTemplate
        projectImage={
          project.featuredImage.node?.localFile?.childImageSharp?.fluid?.src
        }
        projectTitle={project.title}
        links={getNavLinks(data.data)}
        contactUsText={config.contactUsText}
        projectDescription={project.content}
        phone={config.phone}
        email={config.email}
        photos={getPhotos(project.projectFields.photos)}
      />
    </PageStructure>
  )
}

export default ProjectPage

export const ProjectQuery = graphql`
  query ProjectQuery($id: String!) {
    allWpProject(filter: { id: { eq: $id } }) {
      edges {
        node {
          title
          content
          projectFields {
            photos {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 800) {
                    src
                  }
                }
              }
            }
          }
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1000) {
                    src
                  }
                }
              }
              sourceUrl
            }
          }
        }
      }
    }
    headerMenu: wpMenu(id: { eq: "dGVybToyMTk0" }) {
      id
      slug
      menuItems {
        nodes {
          id
          label
          path
        }
      }
    }
    tradeMenu: wpMenu(id: { eq: "dGVybToyMjAw" }) {
      id
      slug
      menuItems {
        nodes {
          id
          label
          path
        }
      }
    }
    allWpConfig {
      edges {
        node {
          configFields {
            phone
            email
            contactUsText
          }
        }
      }
    }
  }
`
